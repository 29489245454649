.component-table th, .component-table footer {
    padding: 8px 4px 8px 4px;
    border-bottom: #000000 1px solid;
}

.component-table th, .component-table footer {
    border-bottom-width: 2px;
}

.component-table td {
    border-bottom: #000000 1px solid;
}

.component-table tr:last-of-type td {
    border-bottom: #000000 2px solid;
}

.component-table footer {
    line-height: 48px;
    padding: 0px;
    height: 48px;
    border-bottom-width: 0px;
}