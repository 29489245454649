$rdl-input-bg: #fff !default;
$rdl-input-disabled-bg: #eee !default;
$rdl-input-border: #ccc !default;
$rdl-input-color: #333 !default;
$rdl-btn-bg: $rdl-input-bg !default;
$rdl-btn-bg-active: darken($rdl-btn-bg, 10%);
$rdl-btn-border: $rdl-input-border !default;
$rdl-btn-border-active: darken($rdl-btn-border, 25%);
$rdl-btn-border-hover: darken($rdl-btn-border, 12%);
$rdl-btn-color: #333 !default;
$rdl-line-height: 1.42857 !default;

.react-dual-listbox {
  box-sizing: border-box;
  display: flex;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  input,
  select {
    &:disabled {
      background: $rdl-input-disabled-bg;
      cursor: not-allowed;
    }
  }

  button,
  select {
    line-height: $rdl-line-height;
    font-family: inherit;
  }
}

.rdl-list-box {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-self: stretch;
}

.rdl-filter,
.rdl-control {
  display: block;
  border: 1px solid $rdl-input-border;
  border-radius: 2px;
  padding: 8px 12px;
  width: 100%;
  color: $rdl-input-color;
  font-size: 14px;
}

.rdl-filter {
  margin-bottom: 10px;
}

.rdl-control-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.rdl-control-label {
  padding: 2px 4px;

  &.rdl-sr-only {
    position: absolute;
    clip: rect(0 0 0 0);
  }
}

.rdl-control {
  flex: 1 0 auto;

  optgroup {
    font: inherit;
    font-weight: 700;
  }
}

.rdl-actions {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0 10px;

  .rdl-has-header & {
    padding-top: 31px;
  }

  .rdl-has-filter & {
    padding-top: 44px;
  }

  .rdl-has-header.rdl-has-filter & {
    padding-top: 75px;
  }
}

.rdl-actions-right,
.rdl-actions-left {
  display: flex;
  flex-direction: column;
}

.rdl-actions-right {
  margin-bottom: 10px;
}

.rdl-move {
  margin-bottom: 5px;
  border: 0px solid $rdl-btn-border;
  border-radius: 0px;
  background-color: transparent;
  cursor: pointer;
  padding: 5px 10px;
  color: $rdl-btn-color;
  font-size: 12px;

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    border-color: $rdl-btn-border-active;
    background: $rdl-btn-bg-active;
  }

  &:focus:not(:disabled) {
    outline: thin dotted;
    outline-offset: -2px;
  }

  &:hover:not(:disabled) {
    border-color: $rdl-btn-border-hover;
    background: $rdl-btn-bg-active;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &:last-child {
    margin-bottom: 0;
  }

  // Decrease spacing between icons
  i {
    margin: 0 -1px;
  }
}

.rdl-align-top {
  .rdl-available {
    margin-right: 10px;
  }

  .rdl-selected {
    margin-left: 10px;
  }

  .rdl-control {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .rdl-actions-left,
  .rdl-actions-right {
    flex-direction: row;
    margin: 0;
  }

  .rdl-list-box .rdl-move {
    flex: 0 1 50%;

    &:first-child {
      margin-bottom: 0;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
