.aau-btn {
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    border-image: none;
}

.aau-btn-shadow {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    cursor: pointer;
}

.btn-tinyiest {
    width: 24px;
    height: 24px;
    padding-top: 4px;
    position: relative;
}

.btn-tinyiest span{
    position: absolute;
    top: 4px;
    left: 4px;
}

.btn-tiny {
    width: 32px;
    height: 32px;
}

.bd-radius-tiny {
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.btn-mini {
    width: 40px;
    height: 40px;
}

.bd-radius-mini {
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.btn-small {
    width: 44px;
    height: 44px;
}

.bd-radius-small {
    border-radius: 22px;
    -moz-border-radius: 22px;
    -webkit-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
}
.btn-medium {
    width: 54px;
    height: 54px;
}

.bd-radius-medium {
    border-radius: 27px;
    -moz-border-radius: 27px;
    -webkit-border-radius: 27px;
    -ms-border-radius: 27px;
    -o-border-radius: 27px;
}

.btn-large {
    width: 62px;
    height: 62px;
}

.bd-radius-large {
    border-radius: 31px;
    -moz-border-radius: 31px;
    -webkit-border-radius: 31px;
    -ms-border-radius: 31px;
    -o-border-radius: 31px;
}