/*!
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

$sizingStep: 4;

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-auto {
    overflow: auto !important;
}

.v-display {
    visibility: visible !important;
}

.v-hidden {
    visibility: hidden !important;
}

.d-block {
    display: block !important;
}

.d-inline {
    display: block !important;
}

.d-none {
    display: none !important;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.center-screen {
    position: absolute;
    display: block;

    top: 50%;
    left: 50%;

    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.pos-absolute {
    position: absolute;
}

.pos-fixed {
    position: fixed;
}

.pos-center {
    position: center;
}

.pos-marker {
    position: marker;
}

.pos-page {
    position: page;
}

.pos-relative {
    position: relative;
}

.pos-static {
    position: static;
}

.pos-sticky {
    position: sticky;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-clear {
    clear: both!important;
}

.fit-content {
    width: fit-content;
}

.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
.my-auto {
    margin-top: auto!important;
    margin-left: auto!important;
}

.ml-auto {margin-left: auto!important;}
.mr-auto {margin-right: auto!important;}
.mt-auto {margin-top: auto!important;}
.mb-auto {margin-bottom: auto!important;}

.p-0 {padding: 0!important;}
.pl-0 {padding-left: 0!important;}
.pr-0 {padding-right: 0!important;}
.pt-0 {padding-top: 0!important;}
.pb-0 {padding-bottom: 0!important;}
.px-0 {padding-left: 0!important; padding-right: 0!important;}
.py-0 {padding-top: 0!important; padding-bottom: 0!important;}
.m-0 {margin: 0!important;}
.ml-0 {margin-left: 0!important;}
.mr-0 {margin-right: 0!important;}
.mt-0 {margin-top: 0!important;}
.mb-0 {margin-bottom: 0!important;}
.mx-0 {margin-left: 0!important; margin-right: 0!important;}
.my-0 {margin-top: 0!important; margin-bottom: 0!important;}

@mixin generate-padding-and-margin($k: 1) {
    $calculatedSize: $k * $sizingStep * 1px;
    $calculatedSizeNegative: -1 * $k * $sizingStep * 1px;

    .p-#{$k} {padding: $calculatedSize !important;}
    .pr-#{$k} {padding-right: $calculatedSize !important;}
    .pl-#{$k} {padding-left: $calculatedSize !important;}
    .pt-#{$k} {padding-top: $calculatedSize !important;}
    .pb-#{$k} {padding-bottom: $calculatedSize !important;}
    .px-#{$k} {padding-left: $calculatedSize!important; padding-right: $calculatedSize!important;}
    .py-#{$k} {padding-top: $calculatedSize!important; padding-bottom: $calculatedSize!important;}

    .m-#{$k} {margin: $calculatedSize !important;}
    .mr-#{$k} {margin-right: $calculatedSize !important;}
    .ml-#{$k} {margin-left: $calculatedSize !important;}
    .mt-#{$k} {margin-top: $calculatedSize !important;}
    .mb-#{$k} {margin-bottom: $calculatedSize !important;}
    .mx-#{$k} {margin-left: $calculatedSize!important; margin-right: $calculatedSize!important;}
    .my-#{$k} {margin-top: $calculatedSize!important; margin-bottom: $calculatedSize!important;}

    .mr-#{$k}-minus {margin-right: $calculatedSizeNegative !important;}
    .ml-#{$k}-minus {margin-left: $calculatedSizeNegative !important;}
    .mt-#{$k}-minus {margin-top: $calculatedSizeNegative !important;}
    .mb-#{$k}-minus {margin-bottom: $calculatedSizeNegative !important;}
}
@for $i from 1 through 40 {
    @include generate-padding-and-margin($i);
}

.width-auto {
    width: auto;
}

.width-fit {
    width: fit-content;
}

@for $i from 0 through 100 {
    .op-#{$i} {
        opacity: $i * 0.01;
    }
}

.height-fit {
    height: fit-content;
}

@for $i from 0 through 1000 {
    .width-#{$i} {
        width: $i * 1%;
    }
    .width-#{$i}-min {
        min-width: $i * 1%;
    }
    .width-#{$i}-max {
        max-width: $i * 1%;
    }
    .width-px-#{$i} {
        width: $i * 1px;
    }
    .width-px-#{$i}-min {
       min-width: $i * 1px;
    }
    .width-px-#{$i}-max {
       max-width: $i * 1px;
    }
    .width-vw-#{$i} {
        width: $i * 1vw;
    }
    .width-vw-#{$i}-min {
        min-width: $i * 1vw;
    }
    .width-vw-#{$i}-max {
        max-width: $i * 1vw;
    }
    .height-#{$i} {
        height: $i * 1%;
    }
    .height-#{$i}-min {
        min-height: $i * 1%;
    }
    .height-#{$i}-max {
        max-height: $i * 1%;
    }
    .height-px-#{$i} {
        height: $i * 1px;
    }
    .height-px-#{$i}-min {
        min-height: $i * 1px;
    }
    .height-px-#{$i}-max {
        max-height: $i * 1px;
    }
    .height-vh-#{$i} {
        height: $i * 1vh;
    }
    .height-vh-#{$i}-min {
        min-height: $i * 1vh;
    }
    .height-vh-#{$i}-max {
        max-height: $i * 1vh;
    }
    .top-#{$i} {
        top: $i * 1%;
    }
    .top-#{$i}-minus {
        top: $i * -1%;
    }
    .top-px-#{$i} {
        top: $i * 1px;
    }
    .top-px-#{$i}-minus {
        top: $i * -1px;
    }
    .left-#{$i} {
        left: $i * 1%;
    }
    .left-#{$i}-minus {
        left: $i * -1%;
    }
    .left-px-#{$i} {
        left: $i * 1px;
    }
    .left-px-#{$i}-minus {
        left: $i * -1px;
    }
    .bottom-#{$i} {
        bottom: $i * 1%;
    }
    .bottom-#{$i}-minus {
        bottom: $i * -1%;
    }
    .bottom-px-#{$i} {
        bottom: $i * 1px;
    }
    .bottom-px-#{$i}-minus {
        bottom: $i * -1px;
    }
    .right-#{$i} {
        right: $i * 1%;
    }
    .right-#{$i}-minus {
        right: $i * -1%;
    }
    .right-px-#{$i} {
        right: $i * 1px;
    }
    .right-px-#{$i}-minus {
        right: $i * -1px;
    }
    .z-#{$i} {
        z-index: $i;
    }
}

.bd-left-none {
    border-left: none !important;
}

.bd-top-none {
    border-top: none !important;
}

.bd-right-none {
    border-right: none !important;
}

.bd-bottom-none {
    border-bottom: none !important;
}

@for $i from 1 through 100 {
    .bd-#{$i} {
        border: $i * 1px solid;
    }
}

@for $i from 1 through 50 {
    .bd-radius-#{$i} {
        border-radius: $i * 1px;
        -moz-border-radius: $i * 1px;
        -webkit-border-radius: $i * 1px;
        -ms-border-radius: $i * 1px;
        -o-border-radius: $i * 1px;
    }
}