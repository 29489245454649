$icomoon-font-family: 'iaau' !default;
$icomoon-font-path: 'fonts' !default;

$iaau-address-book: '\e900';
$iaau-airplane: '\e901';
$iaau-angry: '\e902';
$iaau-arrow-big-redo: '\e903';
$iaau-arrow-big-undo: '\e904';
$iaau-arrow-down: '\e905';
$iaau-arrow-down-left: '\e906';
$iaau-arrow-down-right: '\e907';
$iaau-arrow-forward: '\e908';
$iaau-arrow-left: '\e909';
$iaau-arrow-redo: '\e90a';
$iaau-arrow-reply: '\e90b';
$iaau-arrow-right: '\e90c';
$iaau-arrow-undo: '\e90d';
$iaau-arrow-up: '\e90e';
$iaau-arrow-up-left: '\e90f';
$iaau-arrow-up-right: '\e910';
$iaau-attachment: '\e911';
$iaau-backward: '\e912';
$iaau-barcode: '\e913';
$iaau-bookmark: '\e914';
$iaau-books: '\e915';
$iaau-brightness-contrast: '\e916';
$iaau-bubbles: '\e917';
$iaau-bug: '\e918';
$iaau-burger-1: '\e919';
$iaau-burger-close: '\e91a';
$iaau-calculator: '\e91b';
$iaau-camera: '\e91c';
$iaau-cancel-trash: '\e91d';
$iaau-cart: '\e91e';
$iaau-checkmark: '\e91f';
$iaau-chrome: '\e920';
$iaau-circle-down: '\e921';
$iaau-circle-left: '\e922';
$iaau-circle-right: '\e923';
$iaau-circle-up: '\e924';
$iaau-clipboard: '\e925';
$iaau-clock: '\e926';
$iaau-cloud-download: '\e927';
$iaau-cloud-trash: '\e928';
$iaau-cloud-upload: '\e929';
$iaau-cog: '\e92a';
$iaau-coin-dollar: '\e92b';
$iaau-coin-euro: '\e92c';
$iaau-coin-pound: '\e92d';
$iaau-coin-yen: '\e92e';
$iaau-compass: '\e92f';
$iaau-connection: '\e930';
$iaau-contrast: '\e931';
$iaau-copy-paste: '\e932';
$iaau-credit-card: '\e933';
$iaau-cross: '\e934';
$iaau-down: '\e935';
$iaau-drive: '\e936';
$iaau-drive-full: '\e937';
$iaau-dropbox: '\e938';
$iaau-droplet: '\e939';
$iaau-edge: '\e93a';
$iaau-eject: '\e93b';
$iaau-embed: '\e93c';
$iaau-enlarge: '\e93d';
$iaau-envelop: '\e93e';
$iaau-equalizer: '\e93f';
$iaau-equalizer-1: '\e940';
$iaau-eye: '\e941';
$iaau-eye-blocked: '\e942';
$iaau-eye-close: '\e943';
$iaau-eye-minus: '\e944';
$iaau-eye-open: '\e945';
$iaau-eye-plus: '\e946';
$iaau-facebook: '\e947';
$iaau-feed: '\e948';
$iaau-file-empty: '\e949';
$iaau-file-excel: '\e94a';
$iaau-file-music: '\e94b';
$iaau-file-openoffice: '\e94c';
$iaau-file-pdf: '\e94d';
$iaau-file-picture: '\e94e';
$iaau-file-play: '\e94f';
$iaau-file-video: '\e950';
$iaau-file-word: '\e951';
$iaau-file-zip: '\e952';
$iaau-filter: '\e953';
$iaau-filter-v2: '\e954';
$iaau-firefox: '\e955';
$iaau-first: '\e956';
$iaau-flickr: '\e957';
$iaau-floppy-disk: '\e958';
$iaau-floppy-disk-number: '\e959';
$iaau-folder: '\e95a';
$iaau-folder-check: '\e95b';
$iaau-folder-cross: '\e95c';
$iaau-folder-download: '\e95d';
$iaau-folder-minus: '\e95e';
$iaau-folder-plus: '\e95f';
$iaau-folder-upload: '\e960';
$iaau-forward: '\e961';
$iaau-gift: '\e962';
$iaau-github: '\e963';
$iaau-google: '\e964';
$iaau-google-drive: '\e965';
$iaau-google-plus-1: '\e966';
$iaau-google-plus: '\e967';
$iaau-happy: '\e968';
$iaau-headphones: '\e969';
$iaau-heart: '\e96a';
$iaau-history: '\e96b';
$iaau-home: '\e96c';
$iaau-hour-glass: '\e96d';
$iaau-image: '\e96e';
$iaau-infinite: '\e96f';
$iaau-instagram: '\e970';
$iaau-key: '\e971';
$iaau-last: '\e972';
$iaau-left: '\e973';
$iaau-link: '\e974';
$iaau-linkedin: '\e975';
$iaau-list: '\e976';
$iaau-location: '\e977';
$iaau-map: '\e978';
$iaau-menu: '\e979';
$iaau-menu-burger: '\e97a';
$iaau-mic: '\e97b';
$iaau-minus: '\e97c';
$iaau-neutral: '\e97d';
$iaau-next: '\e97e';
$iaau-on-off: '\e97f';
$iaau-opera: '\e980';
$iaau-paint-format: '\e981';
$iaau-pause-1: '\e982';
$iaau-pause: '\e983';
$iaau-paypal: '\e984';
$iaau-pen: '\e985';
$iaau-pencil: '\e986';
$iaau-phone: '\e987';
$iaau-phone-hang-up: '\e988';
$iaau-pie-chart: '\e989';
$iaau-pinterest: '\e98a';
$iaau-play-1: '\e98b';
$iaau-play: '\e98c';
$iaau-plus: '\e98d';
$iaau-power: '\e98e';
$iaau-power-cord: '\e98f';
$iaau-previous-1: '\e990';
$iaau-previous: '\e991';
$iaau-print: '\e992';
$iaau-profile: '\e993';
$iaau-pushpin: '\e994';
$iaau-qrcode: '\e995';
$iaau-quotes-right: '\e996';
$iaau-reboot: '\e997';
$iaau-renren: '\e998';
$iaau-right: '\e999';
$iaau-rocket: '\e99a';
$iaau-safari: '\e99b';
$iaau-scissors: '\e99c';
$iaau-search: '\e99d';
$iaau-share: '\e99e';
$iaau-shocked: '\e99f';
$iaau-shrink: '\e9a0';
$iaau-shuffle: '\e9a1';
$iaau-sort-amount-asc: '\e9a2';
$iaau-sort-amount-desc: '\e9a3';
$iaau-spinner: '\e9a4';
$iaau-spotify: '\e9a5';
$iaau-star-empty: '\e9a6';
$iaau-star-full: '\e9a7';
$iaau-star-half: '\e9a8';
$iaau-star-quarter: '\e9a9';
$iaau-star-threequarter: '\e9aa';
$iaau-stats-bars: '\e9ab';
$iaau-stats-dots: '\e9ac';
$iaau-steam: '\e9ad';
$iaau-stop: '\e9ae';
$iaau-sun: '\e9af';
$iaau-table: '\e9b0';
$iaau-terminal: '\e9b1';
$iaau-trash: '\e9b2';
$iaau-truck: '\e9b3';
$iaau-twitch: '\e9b4';
$iaau-twitter: '\e9b5';
$iaau-up: '\e9b6';
$iaau-update: '\e9b7';
$iaau-user: '\e9b8';
$iaau-user-check: '\e9b9';
$iaau-user-king: '\e9ba';
$iaau-user-me: '\e9bb';
$iaau-user-minus: '\e9bc';
$iaau-user-plus: '\e9bd';
$iaau-users: '\e9be';
$iaau-user-SA: '\e9bf';
$iaau-view: '\e9c0';
$iaau-view-v2: '\e9c1';
$iaau-vine: '\e9c2';
$iaau-volume-decrease: '\e9c3';
$iaau-volume-high: '\e9c4';
$iaau-volume-increase: '\e9c5';
$iaau-volume-low: '\e9c6';
$iaau-volume-medium: '\e9c7';
$iaau-volume-mute: '\e9c8';
$iaau-warning: '\e9c9';
$iaau-warning-v2: '\e9ca';
$iaau-windows: '\e9cb';
$iaau-windows8: '\e9cc';
$iaau-wordpress: '\e9cd';
$iaau-wrench: '\e9ce';
$iaau-wrench-alert: '\e9cf';
$iaau-wrench-bug: '\e9d0';
$iaau-wrench-incident: '\e9d1';
$iaau-wrench-serv-1: '\e9d4';
$iaau-wrench-serv-2: '\e9d5';
$iaau-youtube: '\e9d6';
$iaau-zoom-in: '\e9d7';
$iaau-zoom-out: '\e9d8';

