@import './variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tk9mx4');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tk9mx4#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tk9mx4') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tk9mx4') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tk9mx4##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.iaau-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iaau-address-book {
  &:before {
    content: $iaau-address-book;
  }
}
.iaau-airplane {
  &:before {
    content: $iaau-airplane;
  }
}
.iaau-angry {
  &:before {
    content: $iaau-angry;
  }
}
.iaau-arrow-big-redo {
  &:before {
    content: $iaau-arrow-big-redo;
  }
}
.iaau-arrow-big-undo {
  &:before {
    content: $iaau-arrow-big-undo;
  }
}
.iaau-arrow-down {
  &:before {
    content: $iaau-arrow-down;
  }
}
.iaau-arrow-down-left {
  &:before {
    content: $iaau-arrow-down-left;
  }
}
.iaau-arrow-down-right {
  &:before {
    content: $iaau-arrow-down-right;
  }
}
.iaau-arrow-forward {
  &:before {
    content: $iaau-arrow-forward;
  }
}
.iaau-arrow-left {
  &:before {
    content: $iaau-arrow-left;
  }
}
.iaau-arrow-redo {
  &:before {
    content: $iaau-arrow-redo;
  }
}
.iaau-arrow-reply {
  &:before {
    content: $iaau-arrow-reply;
  }
}
.iaau-arrow-right {
  &:before {
    content: $iaau-arrow-right;
  }
}
.iaau-arrow-undo {
  &:before {
    content: $iaau-arrow-undo;
  }
}
.iaau-arrow-up {
  &:before {
    content: $iaau-arrow-up;
  }
}
.iaau-arrow-up-left {
  &:before {
    content: $iaau-arrow-up-left;
  }
}
.iaau-arrow-up-right {
  &:before {
    content: $iaau-arrow-up-right;
  }
}
.iaau-attachment {
  &:before {
    content: $iaau-attachment;
  }
}
.iaau-backward {
  &:before {
    content: $iaau-backward;
  }
}
.iaau-barcode {
  &:before {
    content: $iaau-barcode;
  }
}
.iaau-bookmark {
  &:before {
    content: $iaau-bookmark;
  }
}
.iaau-books {
  &:before {
    content: $iaau-books;
  }
}
.iaau-brightness-contrast {
  &:before {
    content: $iaau-brightness-contrast;
  }
}
.iaau-bubbles {
  &:before {
    content: $iaau-bubbles;
  }
}
.iaau-bug {
  &:before {
    content: $iaau-bug;
  }
}
.iaau-burger-1 {
  &:before {
    content: $iaau-burger-1;
  }
}
.iaau-burger-close {
  &:before {
    content: $iaau-burger-close;
  }
}
.iaau-calculator {
  &:before {
    content: $iaau-calculator;
  }
}
.iaau-camera {
  &:before {
    content: $iaau-camera;
  }
}
.iaau-cancel-trash {
  &:before {
    content: $iaau-cancel-trash;
  }
}
.iaau-cart {
  &:before {
    content: $iaau-cart;
  }
}
.iaau-checkmark {
  &:before {
    content: $iaau-checkmark;
  }
}
.iaau-chrome {
  &:before {
    content: $iaau-chrome;
  }
}
.iaau-circle-down {
  &:before {
    content: $iaau-circle-down;
  }
}
.iaau-circle-left {
  &:before {
    content: $iaau-circle-left;
  }
}
.iaau-circle-right {
  &:before {
    content: $iaau-circle-right;
  }
}
.iaau-circle-up {
  &:before {
    content: $iaau-circle-up;
  }
}
.iaau-clipboard {
  &:before {
    content: $iaau-clipboard;
  }
}
.iaau-clock {
  &:before {
    content: $iaau-clock;
  }
}
.iaau-cloud-download {
  &:before {
    content: $iaau-cloud-download;
  }
}
.iaau-cloud-trash {
  &:before {
    content: $iaau-cloud-trash;
  }
}
.iaau-cloud-upload {
  &:before {
    content: $iaau-cloud-upload;
  }
}
.iaau-cog {
  &:before {
    content: $iaau-cog;
  }
}
.iaau-coin-dollar {
  &:before {
    content: $iaau-coin-dollar;
  }
}
.iaau-coin-euro {
  &:before {
    content: $iaau-coin-euro;
  }
}
.iaau-coin-pound {
  &:before {
    content: $iaau-coin-pound;
  }
}
.iaau-coin-yen {
  &:before {
    content: $iaau-coin-yen;
  }
}
.iaau-compass {
  &:before {
    content: $iaau-compass;
  }
}
.iaau-connection {
  &:before {
    content: $iaau-connection;
  }
}
.iaau-contrast {
  &:before {
    content: $iaau-contrast;
  }
}
.iaau-copy-paste {
  &:before {
    content: $iaau-copy-paste;
  }
}
.iaau-credit-card {
  &:before {
    content: $iaau-credit-card;
  }
}
.iaau-cross {
  &:before {
    content: $iaau-cross;
  }
}
.iaau-down {
  &:before {
    content: $iaau-down;
  }
}
.iaau-drive {
  &:before {
    content: $iaau-drive;
  }
}
.iaau-drive-full {
  &:before {
    content: $iaau-drive-full;
  }
}
.iaau-dropbox {
  &:before {
    content: $iaau-dropbox;
  }
}
.iaau-droplet {
  &:before {
    content: $iaau-droplet;
  }
}
.iaau-edge {
  &:before {
    content: $iaau-edge;
  }
}
.iaau-eject {
  &:before {
    content: $iaau-eject;
  }
}
.iaau-embed {
  &:before {
    content: $iaau-embed;
  }
}
.iaau-enlarge {
  &:before {
    content: $iaau-enlarge;
  }
}
.iaau-envelop {
  &:before {
    content: $iaau-envelop;
  }
}
.iaau-equalizer {
  &:before {
    content: $iaau-equalizer;
  }
}
.iaau-equalizer-1 {
  &:before {
    content: $iaau-equalizer-1;
  }
}
.iaau-eye {
  &:before {
    content: $iaau-eye;
  }
}
.iaau-eye-blocked {
  &:before {
    content: $iaau-eye-blocked;
  }
}
.iaau-eye-close {
  &:before {
    content: $iaau-eye-close;
  }
}
.iaau-eye-minus {
  &:before {
    content: $iaau-eye-minus;
  }
}
.iaau-eye-open {
  &:before {
    content: $iaau-eye-open;
  }
}
.iaau-eye-plus {
  &:before {
    content: $iaau-eye-plus;
  }
}
.iaau-facebook {
  &:before {
    content: $iaau-facebook;
  }
}
.iaau-feed {
  &:before {
    content: $iaau-feed;
  }
}
.iaau-file-empty {
  &:before {
    content: $iaau-file-empty;
  }
}
.iaau-file-excel {
  &:before {
    content: $iaau-file-excel;
  }
}
.iaau-file-music {
  &:before {
    content: $iaau-file-music;
  }
}
.iaau-file-openoffice {
  &:before {
    content: $iaau-file-openoffice;
  }
}
.iaau-file-pdf {
  &:before {
    content: $iaau-file-pdf;
  }
}
.iaau-file-picture {
  &:before {
    content: $iaau-file-picture;
  }
}
.iaau-file-play {
  &:before {
    content: $iaau-file-play;
  }
}
.iaau-file-video {
  &:before {
    content: $iaau-file-video;
  }
}
.iaau-file-word {
  &:before {
    content: $iaau-file-word;
  }
}
.iaau-file-zip {
  &:before {
    content: $iaau-file-zip;
  }
}
.iaau-filter {
  &:before {
    content: $iaau-filter;
  }
}
.iaau-filter-v2 {
  &:before {
    content: $iaau-filter-v2;
  }
}
.iaau-firefox {
  &:before {
    content: $iaau-firefox;
  }
}
.iaau-first {
  &:before {
    content: $iaau-first;
  }
}
.iaau-flickr {
  &:before {
    content: $iaau-flickr;
  }
}
.iaau-floppy-disk {
  &:before {
    content: $iaau-floppy-disk;
  }
}
.iaau-floppy-disk-number {
  &:before {
    content: $iaau-floppy-disk-number;
  }
}
.iaau-folder {
  &:before {
    content: $iaau-folder;
  }
}
.iaau-folder-check {
  &:before {
    content: $iaau-folder-check;
  }
}
.iaau-folder-cross {
  &:before {
    content: $iaau-folder-cross;
  }
}
.iaau-folder-download {
  &:before {
    content: $iaau-folder-download;
  }
}
.iaau-folder-minus {
  &:before {
    content: $iaau-folder-minus;
  }
}
.iaau-folder-plus {
  &:before {
    content: $iaau-folder-plus;
  }
}
.iaau-folder-upload {
  &:before {
    content: $iaau-folder-upload;
  }
}
.iaau-forward {
  &:before {
    content: $iaau-forward;
  }
}
.iaau-gift {
  &:before {
    content: $iaau-gift;
  }
}
.iaau-github {
  &:before {
    content: $iaau-github;
  }
}
.iaau-google {
  &:before {
    content: $iaau-google;
  }
}
.iaau-google-drive {
  &:before {
    content: $iaau-google-drive;
  }
}
.iaau-google-plus-1 {
  &:before {
    content: $iaau-google-plus-1;
  }
}
.iaau-google-plus {
  &:before {
    content: $iaau-google-plus;
  }
}
.iaau-happy {
  &:before {
    content: $iaau-happy;
  }
}
.iaau-headphones {
  &:before {
    content: $iaau-headphones;
  }
}
.iaau-heart {
  &:before {
    content: $iaau-heart;
  }
}
.iaau-history {
  &:before {
    content: $iaau-history;
  }
}
.iaau-home {
  &:before {
    content: $iaau-home;
  }
}
.iaau-hour-glass {
  &:before {
    content: $iaau-hour-glass;
  }
}
.iaau-image {
  &:before {
    content: $iaau-image;
  }
}
.iaau-infinite {
  &:before {
    content: $iaau-infinite;
  }
}
.iaau-instagram {
  &:before {
    content: $iaau-instagram;
  }
}
.iaau-key {
  &:before {
    content: $iaau-key;
  }
}
.iaau-last {
  &:before {
    content: $iaau-last;
  }
}
.iaau-left {
  &:before {
    content: $iaau-left;
  }
}
.iaau-link {
  &:before {
    content: $iaau-link;
  }
}
.iaau-linkedin {
  &:before {
    content: $iaau-linkedin;
  }
}
.iaau-list {
  &:before {
    content: $iaau-list;
  }
}
.iaau-location {
  &:before {
    content: $iaau-location;
  }
}
.iaau-map {
  &:before {
    content: $iaau-map;
  }
}
.iaau-menu {
  &:before {
    content: $iaau-menu;
  }
}
.iaau-menu-burger {
  &:before {
    content: $iaau-menu-burger;
  }
}
.iaau-mic {
  &:before {
    content: $iaau-mic;
  }
}
.iaau-minus {
  &:before {
    content: $iaau-minus;
  }
}
.iaau-neutral {
  &:before {
    content: $iaau-neutral;
  }
}
.iaau-next {
  &:before {
    content: $iaau-next;
  }
}
.iaau-on-off {
  &:before {
    content: $iaau-on-off;
  }
}
.iaau-opera {
  &:before {
    content: $iaau-opera;
  }
}
.iaau-paint-format {
  &:before {
    content: $iaau-paint-format;
  }
}
.iaau-pause-1 {
  &:before {
    content: $iaau-pause-1;
  }
}
.iaau-pause {
  &:before {
    content: $iaau-pause;
  }
}
.iaau-paypal {
  &:before {
    content: $iaau-paypal;
  }
}
.iaau-pen {
  &:before {
    content: $iaau-pen;
  }
}
.iaau-pencil {
  &:before {
    content: $iaau-pencil;
  }
}
.iaau-phone {
  &:before {
    content: $iaau-phone;
  }
}
.iaau-phone-hang-up {
  &:before {
    content: $iaau-phone-hang-up;
  }
}
.iaau-pie-chart {
  &:before {
    content: $iaau-pie-chart;
  }
}
.iaau-pinterest {
  &:before {
    content: $iaau-pinterest;
  }
}
.iaau-play-1 {
  &:before {
    content: $iaau-play-1;
  }
}
.iaau-play {
  &:before {
    content: $iaau-play;
  }
}
.iaau-plus {
  &:before {
    content: $iaau-plus;
  }
}
.iaau-power {
  &:before {
    content: $iaau-power;
  }
}
.iaau-power-cord {
  &:before {
    content: $iaau-power-cord;
  }
}
.iaau-previous-1 {
  &:before {
    content: $iaau-previous-1;
  }
}
.iaau-previous {
  &:before {
    content: $iaau-previous;
  }
}
.iaau-print {
  &:before {
    content: $iaau-print;
  }
}
.iaau-profile {
  &:before {
    content: $iaau-profile;
  }
}
.iaau-pushpin {
  &:before {
    content: $iaau-pushpin;
  }
}
.iaau-qrcode {
  &:before {
    content: $iaau-qrcode;
  }
}
.iaau-quotes-right {
  &:before {
    content: $iaau-quotes-right;
  }
}
.iaau-reboot {
  &:before {
    content: $iaau-reboot;
  }
}
.iaau-renren {
  &:before {
    content: $iaau-renren;
  }
}
.iaau-right {
  &:before {
    content: $iaau-right;
  }
}
.iaau-rocket {
  &:before {
    content: $iaau-rocket;
  }
}
.iaau-safari {
  &:before {
    content: $iaau-safari;
  }
}
.iaau-scissors {
  &:before {
    content: $iaau-scissors;
  }
}
.iaau-search {
  &:before {
    content: $iaau-search;
  }
}
.iaau-share {
  &:before {
    content: $iaau-share;
  }
}
.iaau-shocked {
  &:before {
    content: $iaau-shocked;
  }
}
.iaau-shrink {
  &:before {
    content: $iaau-shrink;
  }
}
.iaau-shuffle {
  &:before {
    content: $iaau-shuffle;
  }
}
.iaau-sort-amount-asc {
  &:before {
    content: $iaau-sort-amount-asc;
  }
}
.iaau-sort-amount-desc {
  &:before {
    content: $iaau-sort-amount-desc;
  }
}
.iaau-spinner {
  &:before {
    content: $iaau-spinner;
  }
}
.iaau-spotify {
  &:before {
    content: $iaau-spotify;
  }
}
.iaau-star-empty {
  &:before {
    content: $iaau-star-empty;
  }
}
.iaau-star-full {
  &:before {
    content: $iaau-star-full;
  }
}
.iaau-star-half {
  &:before {
    content: $iaau-star-half;
  }
}
.iaau-star-quarter {
  &:before {
    content: $iaau-star-quarter;
  }
}
.iaau-star-threequarter {
  &:before {
    content: $iaau-star-threequarter;
  }
}
.iaau-stats-bars {
  &:before {
    content: $iaau-stats-bars;
  }
}
.iaau-stats-dots {
  &:before {
    content: $iaau-stats-dots;
  }
}
.iaau-steam {
  &:before {
    content: $iaau-steam;
  }
}
.iaau-stop {
  &:before {
    content: $iaau-stop;
  }
}
.iaau-sun {
  &:before {
    content: $iaau-sun;
  }
}
.iaau-table {
  &:before {
    content: $iaau-table;
  }
}
.iaau-terminal {
  &:before {
    content: $iaau-terminal;
  }
}
.iaau-trash {
  &:before {
    content: $iaau-trash;
  }
}
.iaau-truck {
  &:before {
    content: $iaau-truck;
  }
}
.iaau-twitch {
  &:before {
    content: $iaau-twitch;
  }
}
.iaau-twitter {
  &:before {
    content: $iaau-twitter;
  }
}
.iaau-up {
  &:before {
    content: $iaau-up;
  }
}
.iaau-update {
  &:before {
    content: $iaau-update;
  }
}
.iaau-user {
  &:before {
    content: $iaau-user;
  }
}
.iaau-user-check {
  &:before {
    content: $iaau-user-check;
  }
}
.iaau-user-king {
  &:before {
    content: $iaau-user-king;
  }
}
.iaau-user-me {
  &:before {
    content: $iaau-user-me;
  }
}
.iaau-user-minus {
  &:before {
    content: $iaau-user-minus;
  }
}
.iaau-user-plus {
  &:before {
    content: $iaau-user-plus;
  }
}
.iaau-users {
  &:before {
    content: $iaau-users;
  }
}
.iaau-user-SA {
  &:before {
    content: $iaau-user-SA;
  }
}
.iaau-view {
  &:before {
    content: $iaau-view;
  }
}
.iaau-view-v2 {
  &:before {
    content: $iaau-view-v2;
  }
}
.iaau-vine {
  &:before {
    content: $iaau-vine;
  }
}
.iaau-volume-decrease {
  &:before {
    content: $iaau-volume-decrease;
  }
}
.iaau-volume-high {
  &:before {
    content: $iaau-volume-high;
  }
}
.iaau-volume-increase {
  &:before {
    content: $iaau-volume-increase;
  }
}
.iaau-volume-low {
  &:before {
    content: $iaau-volume-low;
  }
}
.iaau-volume-medium {
  &:before {
    content: $iaau-volume-medium;
  }
}
.iaau-volume-mute {
  &:before {
    content: $iaau-volume-mute;
  }
}
.iaau-warning {
  &:before {
    content: $iaau-warning;
  }
}
.iaau-warning-v2 {
  &:before {
    content: $iaau-warning-v2;
  }
}
.iaau-windows {
  &:before {
    content: $iaau-windows;
  }
}
.iaau-windows8 {
  &:before {
    content: $iaau-windows8;
  }
}
.iaau-wordpress {
  &:before {
    content: $iaau-wordpress;
  }
}
.iaau-wrench {
  &:before {
    content: $iaau-wrench;
  }
}
.iaau-wrench-alert {
  &:before {
    content: $iaau-wrench-alert;
  }
}
.iaau-wrench-bug {
  &:before {
    content: $iaau-wrench-bug;
  }
}
.iaau-wrench-incident {
  &:before {
    content: $iaau-wrench-incident;
  }
}
.iaau-wrench-serv-1 {
  &:before {
    content: $iaau-wrench-serv-1;
  }
}
.iaau-wrench-serv-2 {
  &:before {
    content: $iaau-wrench-serv-2;
  }
}
.iaau-youtube {
  &:before {
    content: $iaau-youtube;
  }
}
.iaau-zoom-in {
  &:before {
    content: $iaau-zoom-in;
  }
}
.iaau-zoom-out {
  &:before {
    content: $iaau-zoom-out;
  }
}

