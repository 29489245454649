.component-logfile {
    height: auto;
    width: auto;
    font-family: monospace;
    overflow: auto;
    min-height: 600px;
}

.component-logfile span {
    white-space: pre;
}