/*!
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

.link-none {
  text-decoration: none;
}

.link-none:hover {
  font-weight: bold;
}

.text-normal{
  font-weight: normal;
}

.text-bold{
  font-weight: bold !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-nodecoration{
  text-decoration: none !important;
}

.tally {
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
}

p {
  margin-block-start: 0px;
}

@for $i from 5 through 100 {
    .font-#{$i} {
        font-size: $i * 1px;
    }
}

.text-wrap {
  overflow-wrap: anywhere;
}

.text-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-nopointer {
  cursor: default;
}