.iaau-inline {
    font-size: 12px;
    line-height: 12px;
}

.iaau-tinyiest {
    font-size: 12px;
    line-height: 12px;
}

.iaau-tiny {
    font-size: 16px;
    line-height: 16px;
}

.iaau-mini {
    font-size: 24px;
    line-height: 24px;
}

.iaau-small {
    font-size: 28px;
    line-height: 28px;
}

.iaau-medium {
    font-size: 38px;
    line-height: 38px;
}

.iaau-large {
    font-size: 46px;
    line-height: 46px;
}